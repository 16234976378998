import { User } from "components/models/UserModel";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { NotificationModel } from "components/models/NotificationModel";
import { FriendModel } from "components/models/FriendModel";
import { RecipientModel } from "components/models/RecipientModel";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { IGenericContact } from "components/models/IGenericContact";

// state.ts
// Type for all elements
export interface State {
  wallets: IGenericAccount[];
  // contacts: Contacts[];
  me: User;
  selectedAccountIndex: number;
  makePayment: {
    sender: IGenericAccount;
    recipient: IGenericAccount;
    amount: any;
    currency: string;
    note: string;
    isMK: boolean;
  };
  notifications: NotificationModel[];
  contacts: IGenericContact[];
  recipients: IGenericAccount[];
  searchSharedAccounts: string;
  selectedContactToAdd: User;
  selectedContactToAccept: IGenericContact;
  selectedContactForDetails: IGenericContact;
  selectedTransactionIndexForDetails: number;
  selectCountryBank: string;
  selectedBankToAdd: YapilyBankDetailsModel;
}

// First initialState
export const initialState: State = {
  wallets: [],
  me: undefined,
  selectedAccountIndex:  parseInt(localStorage.getItem("selectedAccountIndex")) || 0,
  makePayment: {
    sender: undefined,
    recipient: undefined,
    amount: undefined,
    currency: undefined,
    note: undefined,
    isMK: false,
  },
  notifications: [],
  contacts: [],
  recipients: [],
  searchSharedAccounts: undefined,
  selectedContactToAdd: undefined,
  selectedContactToAccept: undefined,
  selectedContactForDetails: undefined,
  selectedTransactionIndexForDetails: undefined,
  selectCountryBank: undefined,
  selectedBankToAdd: undefined,
};
